import React, { useState } from 'react';
import ReactDOM from 'react-dom'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Loader } from "@googlemaps/js-api-loader"
import DatePicker from "react-datepicker";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";

var classNames = require('classnames');
var _ = require('lodash');

class W3wResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {show: false, selectedRow: null};
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.loader = new Loader({
      apiKey: this.props.google_api_key,
      version: "weekly",
    });
  }

  handleShow(row) {
    var here = this;
    this.setState({show: true, selectedRow: row}, function() {
      if(this.state.selectedRow.lattitude !== null) {
        here.loader.load().then(() => {
          const myLatLng = { lat: row.lattitude, lng: row.longitude };
          const map = new google.maps.Map(document.getElementById("map"), { center: myLatLng, zoom: 19});
          new google.maps.Marker({position: myLatLng, map});
        });
      }
      else {
        document.getElementById("map").innerHTML = "";
      }
    });
  }

  handleClose() {
    this.setState({show: false});
  }

  render() {
    return (
      <>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Recipient</th>
                <th scope="col"> <img height="25" src={"https://sms.eu-central-1.linodeobjects.com/w3w.png"} /> </th>
                <th scope="col">Reference</th>
                <th scope="col">Status</th>
                <th scope="col">View Details</th>
              </tr>
            </thead>
            <tbody>
              {this.props.w3w_log_views.map((row) =>
                <tr key={row.id}>
                  <th><span className="badge badge-secondary">{row.id}</span></th>
                  <td><span>{row.numbers}</span></td>
                  <td>{row.w3w !== null && <a href={"https://what3words.com/" + row.w3w} target="_blank" rel="nofollow">{row.w3w}</a>}</td>
                  <td>{row.user_ref}</td>
                  <td>
                    <span className={classNames("badge", row.status_color)}>
                      <i className={classNames("bi", row.status_icon)} style={{fontSize: "1rem"}}></i>
                      {row.code_description !== undefined && row.code_description.description}
                    </span>
                  </td>
                  <td>
                    <Button variant="primary" size="sm" onClick={this.handleShow.bind(this, row)}>
                      <i className="bi bi-search"></i>
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose}>
          {this.state.selectedRow !== null &&
            <>
              <div className="modal-header">
                <h5 className="modal-title">Full Details for Location Request <span className="badge badge-secondary"> {this.state.selectedRow.id} </span></h5>
                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              </div>

              <Modal.Body>
                <p><strong>Recipient: </strong>{this.state.selectedRow.numbers}</p>
                <p><strong>Your Reference: </strong>{this.state.selectedRow.user_ref}</p>
                <p><strong>Requested At: </strong>{this.state.selectedRow.created_at_f_t}</p>
                {this.state.selectedRow.w3w !== null &&
                  <>
                    <div><strong>what3words Location: </strong></div>
                    <div className="mb-3"><what3words-address words={this.state.selectedRow.w3w} tooltip-location="location received from the request"/></div>
                  </>
                }
                <div><strong>GPS Coordinates: </strong></div>
                <div>{this.state.selectedRow.lattitude !== null ? [this.state.selectedRow.lattitude, this.state.selectedRow.longitude].join(', ') : "-"}</div>
                <div><strong>Mapped: </strong></div>
                <div id="map"></div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          }
        </Modal>
      </>
    );
  }
}

document.addEventListener('DOMContentLoaded', () => {
  var element = document.getElementById("w3w_results")
  if (element != null) {
    const data = JSON.parse(element.getAttribute('data'));
    ReactDOM.render(<W3wResults {...data} />, element);
  }
})

function W3wDatePicker(props) {
  const [startDate, setStartDate] = useState(new Date(props.date_from));
  const [endDate, setEndDate] = useState(new Date(props.date_to));
  return (
    <div className="border p-4">
      <h5 className="text-center">Date Filter</h5>
      <div className="form-group row">
        <label htmlFor="startDate" className="col-xl-2 col-form-label">From:</label>
        <div className="col-xl-10">
          <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} selectsStart startDate={startDate} endDate={endDate} dateFormat="yyyy-MM-dd" className="form-control" id="startDate" name="date_from" />
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="endDate" className="col-xl-2 col-form-label">To:</label>
        <div className="col-xl-10">
          <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} selectsEnd startDate={startDate} endDate={endDate} minDate={startDate} dateFormat="yyyy-MM-dd" className="form-control" id="endDate" name="date_to" />
        </div>
      </div>
    </div>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  var element = document.getElementById("w3w_date_picker")
  if (element != null) {
    const data = JSON.parse(element.getAttribute('data'));
    ReactDOM.render(<W3wDatePicker {...data} />, element);
  }
})

const W3wStatusSelector = (props) => {
  return (
    <div className="form-group row">
      <label htmlFor="status" className="col-xl-3 col-form-label">Status:</label>
      <div className="col-xl-9">
        <Select defaultValue={props.options.filter(option => props.selected_options.find(selected_option => selected_option == option.id_value))} options={props.options} isMulti name="status[]" id="status" getOptionLabel={option => option.description} getOptionValue={option => option.id_value} />
      </div>
    </div>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  var element = document.getElementById("w3w_status_selector")
  if (element != null) {
    const data = JSON.parse(element.getAttribute('data'));
    ReactDOM.render(<W3wStatusSelector {...data} />, element);
  }
})
